import React from "react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { /*NavLink,*/ useHistory } from "react-router-dom";
import { Box, AppBar, Toolbar, Container, CssBaseline, IconButton, Grid, Menu, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EarthIcon from "../Icons/Earth";
import Link from "@material-ui/core/Link";
// import CheckIcon from "@material-ui/icons/Check";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import LangList from "../Const/LangList";
import LoadingBackdrop from "./LoadingBackdrop";
import { ShopDetail } from "../common/interface";
import { updteLang } from "../Actions/Actions";
import moment from "moment";
import "moment/locale/ja";
import "moment/locale/vi";
import "moment/locale/zh-tw";
import AddGoogleAnalysis from "../common/AddGoogleAnalysis";
import RemoveGoogleAnalysis from "../common/RemoveGoogleAnalysis";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    footerroot: {
        justifyContent: "center",
    },
    footer: {
        "& > * + *": {
            marginLeft: theme.spacing(2),
        },
        [theme.breakpoints.down("md")]: {
            fontSize: "0.5rem",
        },
    },
    link: {
        color: "white",
        cursor: "pointer",
    },
    icon: {
        width: "48px",
    },
    container: {
        minHeight: "calc( 100vh - 128px )",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down("xs")]: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
    },
}));
 interface Map {
     [key: string]: any;
     [index: number]: any;
 }
 const footer = {
     jp_ja: {
        privacy: "https://toreta.in/terms/pp_web_reservation/#lang-japanese",
         terms: "https://toreta.in/terms/web_reservation/",
         poweredBy: "https://toreta.in/",
     },
     jp_all: {    
         privacy: "https://toreta.in/terms/pp_web_reservation/#lang-english",
         terms: "https://toreta.in/terms/web_reservation_en/",
         poweredBy: "https://toreta.in/",
     },
     default: {
         privacy: "http://toretaasia.com/privacy-policy/",
         terms: "http://toretaasia.com/terms-of-service-2/",
         poweredBy: "http://toretaasia.com/",
     },
 };
type Props = {};
// function isValidKey(key: string, obj: { [propName: string]: any }): boolean {
//     return key in obj;
// }

const Layout: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const currentLanguage: string = useSelector((state: any) => state.lang);
    const shop: ShopDetail = useSelector((state: any) => state.shop);
    const [getFooter,setFooter]=React.useState(footer.default);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { i18n } = useTranslation();
    const appbarColorStyle = {
        backgroundColor: shop.key ? shop.color || "#3f51b5" : "white",
        transition: "background-color 100ms ease-in-out, color 100ms ease-in-out",
    };
    const moveTo = (url: string) => {
        const preventDefault = (event: React.SyntheticEvent) => {
            event.preventDefault();
            history.push(url);
        };
        return preventDefault;
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const setFooterMenu=()=>{
        if(shop.company_country=="jp"){
            if(currentLanguage=="ja"){
                setFooter(footer.jp_ja);
            }
            else{
                setFooter(footer.jp_all);
            }
        }else{
            setFooter(footer.default);
        }
    }
    const handleLangChange = (value: string) => {
        updteLang(value);
        handleClose();
    };

    React.useEffect(() => {
        i18n.changeLanguage(currentLanguage);
        moment.locale(currentLanguage);
        setFooterMenu();
    }, [currentLanguage, i18n]);
    React.useEffect(() => {
        setFooterMenu();
        // shop info change;
        if (shop.ga) {
            if (shop.ga.type === "GTM" && shop.ga.code !== "") {
                AddGoogleAnalysis(shop.ga.code);
            }
        }
        return () => {
            if (shop.ga && shop.ga.code !== "") {
                RemoveGoogleAnalysis(shop.ga.code);
            }
        };
    }, [shop]);
    return (
        <>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={currentLanguage}>
                <LoadingBackdrop />
                <AppBar position="static" style={appbarColorStyle}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            <Link rel="noopener" target="_blank" className={classes.link} onClick={shop?.public_key ? moveTo(`/`) : () => {}}>
                                {shop.name ? shop.name : ""}
                            </Link>
                        </Typography>
                        <Box>
                            <IconButton className={classes.icon} aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                                <EarthIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                {LangList.map((lang) => (
                                    <MenuItem key={`${lang.value}-menu`} onClick={() => handleLangChange(lang.value)}>
                                        {currentLanguage === lang.value ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}　{lang.name}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
                <CssBaseline />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid>{props.children}</Grid>
                </Container>
                <AppBar position="static" style={appbarColorStyle}>
                    <Toolbar className={classes.footerroot}>
                        <Typography className={classes.footer}>
                            <Link rel="noopener" target="_blank" className={classes.link} href={`${getFooter.privacy}`}>
                                {t("PRIVACY_POLICY")}
                            </Link>
                            <Link rel="noopener" target="_blank" className={classes.link} href={`${getFooter.terms}`}>
                                {t("TERMS_OF_SERVICE")}
                            </Link>
                            <Link
                                rel="noopener"
                                target="_blank"
                                href={`${getFooter.poweredBy}`}
                                className={classes.link}
                                // onClick={preventDefault}
                            >
                                powered by <span className="icon-logo"></span>
                            </Link>
                        </Typography>
                    </Toolbar>
                </AppBar>
            </MuiPickersUtilsProvider>
        </>
    );
};
export default Layout;
