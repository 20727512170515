import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import moment, { invalid } from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { /*NavLink,*/ useHistory } from "react-router-dom";
import { UseAppContext } from "../Context/App";
import Actions from "../Actions/Actions";
import {
    StripePI,
    ToretaReservationSearchBody,
    ToretaReservationsDaysResponse,
    YoyakuFullBody,
    YoyakuBody,
    PayInfo,
    ShopDetail,
    ShopInfo,
    HolidaySettings,
} from "../common/interface";
export interface ToretaError {
    status: number;
    detail: string;
}

const ToretaApi = () => {
    const currentLanguage: string = useSelector((state: any) => state.lang);
    const shop: ShopDetail = useSelector((state: any) => state.shop);
    const { t } = useTranslation();

    const appContext = UseAppContext();
    const history = useHistory();
    const publicKey = appContext?.publicKey;

    // const loadings = appContext?.loadings;
    const ScrollTop = () => {
        window.scrollTo(0, 0);
    };
    //取得餐廳設定值
    const GetShopDetail = async () => {
        // console.log(Actions);
        Actions.loadingControl(true);
        
        return await axios
            .get(`/api/${publicKey}/info`)
            .then(async (response) => {
                if(response.data.enabled){
                    var shop=response.data;
                    var defaultLang="en";
                    const savedLang = localStorage.getItem("lang") ? localStorage.getItem("lang") : '';
                    const browserLang = navigator.language;
                    if(savedLang){
                        defaultLang = savedLang;
                    }
                    else if(browserLang){
                        defaultLang = browserLang;
                    }
                    else if(document.location.hash.indexOf("/pay/")>0 && shop.country=="jp"){
                        defaultLang="ja";
                    }
                    localStorage.setItem("lang",defaultLang);
                    Actions.updateShopDetail(response.data);
                    document.title = `${response.data.name} | Toreta Web Reservation`;
                }else{
                    //console.log("shop disabled:",response.data);
                    history.push("/error/SHOPNOTEXIST");
                }
            })
            .catch((error) => {
                //console.error(error);
                if(error.response.status === 504 || error.response.status === 503 || error.response.status === 502){
                    history.push("/error/SERVER_UNAVAILABLE");
                }else{
                    history.push("/error/SHOPNOTEXIST");
                }
                
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const GetReserveByStaff =  (hash:string) => {
        Actions.loadingControl(true);
        return axios
            .get(`/api/${publicKey}/reservequeue/${hash}`)
            .then((response) => {
                //console.log("begin updateYoyakuBody",response.data);
                response.data.toretaInfo.reservationIn.reserveNo = response.data.reserveNo;
                response.data.toretaInfo.reservationIn.status = response.data.status;
                return response.data.toretaInfo.reservationIn;//status in (0,1,2)
            })
            .catch((error) => {
                //console.error(error);
                history.push("/error/PAYMENT_EXPIRED");//status = 5
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };

    const GetShopOther = async () => {
        Actions.loadingControl(true);
        return await axios
            .get(`/api/${publicKey}/other`)
            .then((response) => {
                const data: ShopInfo = response.data;
                data.courses?.forEach((a) => {
                  if (a.price) {
                    a.price = parseFloat(`${a.price}`).toFixed(2);
                  }
                });
                Actions.updateShopOther(data);
            })
            .catch((error) => {
                //console.error(error);
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };

    const GetHolidaySetting = async (country: string) => {
        Actions.loadingControl(true);
        return await axios
        .get(`/api/${publicKey}/holidaySetting/${country}`)
            .then((response) => {
                const data = response.data;
                return data;
            })
            .catch((error) => {
                //console.error(error);
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    
    const GetEmptySetas = async (data: ToretaReservationSearchBody): Promise<ToretaReservationsDaysResponse> => {
        Actions.loadingControl(true);
        return await axios
            .post(`/api/${publicKey}/seats`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                //console.error(error);
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const GetDebitAmount = async (data: YoyakuBody): Promise<any> => {
        Actions.loadingControl(true);
        return await axios
            .post(`/api/${publicKey}/debitamount`, data)
            .then((response) => {
              //Luke: 2021-12-03 show price in $00.00 format
              return parseFloat(`${response.data}`).toFixed(2);
            })
            .catch((error) => {
                //console.error(error);
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };

    const AddReserveQueue = async (data: YoyakuFullBody): Promise<any> => {
        Actions.loadingControl(true);
        return await axios.post(`/api/${publicKey}/reservequeue`, data).finally(() => {
            Actions.loadingControl(false);
        });
    };   
    const AddReserveFull = async (initValue: YoyakuBody, gmoIn: PayInfo | null, nextAction: any) => {
        initValue.language=currentLanguage;
        const senddata: YoyakuFullBody = {
            gmoIn: gmoIn,
            reservationIn: initValue,
            lang: currentLanguage,
        };
        AddReserve(senddata).then((response) => {
            if (response.status === "SUCCESS") {
                Swal.fire({ title: t("FINISH.EXPLAIN"), timer: 2000 });
                history.push("/reserve-info/finish");
                nextAction && nextAction();
            } else {
                const status = response.status;
                if (status > 9000) {
                    if (response.gmoError) {
                        Swal.fire({
                            icon: "error",
                            title: response.gmoError.err_detail,
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: t("ERROR.FAIL"),
                        });
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: response.detail,
                    });
                }
            }
        });
    };
    const GetStripePK = async (initValue: YoyakuBody): Promise<StripePI> => {
        Actions.loadingControl(true);
        return await axios
            .post(`/api/${publicKey}/stripepayment`, initValue)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: t("ERROR.STRIPE_APIKEY"),
                });
                //console.error(error);
                return { PK: "", clientSecret: "", id: "" } as StripePI;
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const GetStripeFund = async (piId: undefined | string): Promise<any> => {
        Actions.loadingControl(true);
        return await axios
            .get(`/api/${publicKey}/stripefund/${piId}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                //console.error(error);
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const FinishStripeFund = async (piId: string): Promise<any> => {
        Actions.loadingControl(true);
        return await axios
            .post(`/api/${publicKey}/stripefund/${piId}`)
            .then((response) => {
                Swal.fire({
                    title: t("FUND.FIN"),
                });
                history.push("/");
            })
            .catch((error) => {
                //console.error(error);
                Swal.fire({
                    icon: "error",
                    title: t("ERROR.CONTACT_SYSTEM_MANAGER"),
                });
                return {};
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const createPaymentErrorHandle = (stripe: string, failAction: () => {}) => {
        return (error: any) => {
            failAction && failAction();
            const response = error.response;
            if (response) {
                const data = response.data;
                const status = data.status;
                if (status >= 10000) {
                    // Gmo series error
                    Swal.fire({
                        icon: "error",
                        title: t("ERROR.STRIPE_APIKEY"),
                        text: t("ERROR.CONTACT_SYSTEM_MANAGER"),
                    });
                } else if (status > 9000) {
                    // Gmo series error
                    if (data.gmoError) {
                        Swal.fire({
                            icon: "error",
                            title: data.gmoError.err_detail,
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: t("ERROR.FAIL"),
                        });
                    }
                } else {
                    const toretaApiErros = t("APIERROR.WEB_RESERVATIONS", { returnObjects: true });
                    if (toretaApiErros[status]) {
                        Swal.fire({
                            icon: "error",
                            title: toretaApiErros[status],
                            text: stripe ? t("APIERROR.WEB_RESERVATIONS.000") : "",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                history.push("/");
                            }
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: data.detail,
                        });
                    }
                }
                if (status === "error") {
                    if (data.message) {
                        Swal.fire({
                            icon: "error",
                            title: data.message,
                        });
                    }
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: error.message,
                });
            }
        };
    };

    const AddReserveByStaff = async (initValue: YoyakuBody, nextAction: any, failAction: any) => {
        initValue.language=currentLanguage;
        const senddata: YoyakuFullBody = {
            reservationIn: initValue,
            lang: currentLanguage,
        };
        //console.log("AddReserveByStaff nextAction:",nextAction,"sending request:",senddata);
        return AddReserveQueue(senddata)
            .then((response) => {
                const data = response.data;
                //console.log("AddReserveByStaff response:",data);

                if (data.pay_url) {
                    Swal.fire({ title: t("FINISH.CONTINUE_SMS"), timer: 3000 });
                    nextAction && nextAction();
                } else {
                    Swal.fire({ title: t("APIERROR.WEB_RESERVATIONS.503"), timer: 3000 });
                    failAction && failAction();
                }
            })
            .catch((error) => {
                //console.error("AddReserveByStaff response error:",error);
                Swal.fire({ title: t("APIERROR.WEB_RESERVATIONS.503"), timer: 3000 });
                failAction && failAction();
            });
    };
    //call addReservationsWithPayment in backend
    const AddReserve = async (data: YoyakuFullBody): Promise<any> => {
        Actions.loadingControl(true);
        return await axios.post(`/api/${publicKey}/reserve`, data).finally(() => {
            Actions.loadingControl(false);
        });
    };
    //CHECKOUT_STEP:1 (normal)(new reservation)
    const AddReserveSripe = async (initValue: YoyakuBody, stripe: string, nextAction: any, failAction: any) => {
        initValue.language = currentLanguage;
        const senddata: YoyakuFullBody = {
            stripe: stripe,
            reservationIn: initValue,
            lang: currentLanguage,
        };

        return AddReserve(senddata)
            .then((response) => {
                const data = response.data;
                //console.log("ToretaApi AddReserveSripe-->AddReserve response:",data);
                if (data.status === "PAY") {
                    nextAction && nextAction(); //CHECKOUT_STEP:2 goto checkout form
                } 
                if (data.status === "SUCCESS") {
                    Swal.fire({ title: t("FINISH.EXPLAIN"), timer: 2000 });
                    history.push("/reserve-info/finish");
                    //nextAction && nextAction();
                }
                if (data.status === "PAY-SUCCESS") {
                    Swal.fire({ title: t("FINISH.EXPLAIN"), timer: 2000 });
                    history.push("/pay-info/finish");
                    //nextAction && nextAction();//TODO: /pay-info/finish
                }
                if (data.status === "CONFIRM") {
                    return data;
                }
            })
            .catch(createPaymentErrorHandle(stripe, failAction));
    };
    //CHECKOUT_STEP:1 (attach to existing reservation)
    const BeginPayStripe = async (initValue: YoyakuBody, stripe: string, nextAction: any, failAction: any) => {
        initValue.language = currentLanguage;
        const senddata: YoyakuFullBody = {
            stripe: stripe,
            reservationIn: initValue,
            lang: currentLanguage,
        };
        //console.log("ToretaApi BeginPayStripe, before-->AddReserve, senddata:",senddata);
        return AddReserve(senddata)
            .then((response) => {
                const data = response.data;
                //console.log("ToretaApi BeginPayStripe-->AddReserve response:",data);
                if (data.status === "PAY") {
                    nextAction && nextAction(); //CHECKOUT_STEP:2 goto checkout form
                }
                if (data.status === "CONFIRM") {
                    return data; //TODO: 
                }
                if (data.status === "SUCCESS") {
                    Swal.fire({ title: t("FINISH.EXPLAIN"), timer: 2000 });
                    history.push("/pay-info/finish");
                    //nextAction && nextAction();
                }
            })
            .catch(()=>{
                //console.log("ToretaApi BeginPayStripe, error:");
                createPaymentErrorHandle(stripe, failAction);
            });
    };

    //checkout final step (normal)(new reservation)
    const AddReserveSripeWithIntent = async (initValue: YoyakuBody, stripe: string, nextAction: any, failAction: any) => {
        initValue.language = currentLanguage;
        const senddata: YoyakuFullBody = {
            stripe: stripe,
            reservationIn: initValue,
            lang: currentLanguage,
        };
        Actions.loadingControl(true);

        return await axios
            .post(`/api/${publicKey}/reservewithintent`, senddata)
            .finally(() => {
                Actions.loadingControl(false);
            })
            .then((response) => {
                const data = response.data;
                //console.log("7 ToretaApi AddReserveSripeWithIntent, response:",data);
                if (data.status === "SUCCESS") {
                    Swal.fire({ title: t("FINISH.EXPLAIN"), timer: 2000 });
                    history.push("/reserve-info/finish");//pass reserveNo with req.session
                    //nextAction && nextAction(data.reservation_no);
                }
            })
            .catch(()=>{
                //console.log("7 ToretaApi AddReserveSripeWithIntent, error:");
                createPaymentErrorHandle(stripe, failAction);
            });
    };
    //checkout final step (attach to existing reservation)
    const PayStripeWithIntent = async (rq_hash:string,initValue: YoyakuBody, stripe: string, nextAction: any, failAction: any) => {
        initValue.language = currentLanguage;
        const senddata: YoyakuFullBody = {
            stripe: stripe,
            reservationIn: initValue,
            lang: currentLanguage,
        };
        Actions.loadingControl(true);

        return await axios
            .post(`/api/${publicKey}/paywithintent/${rq_hash}`, senddata)
            .finally(() => {
                Actions.loadingControl(false);
            })
            .then((response) => {
                const data = response.data;
                //console.log("7 ToretaApi PayStripeWithIntent, response:",data);

                if (data.status === "SUCCESS") {
                    Swal.fire({ title: t("FINISH.EXPLAIN"), timer: 2000 });
                    history.push("/pay-info/finish");//pass reserveNo with req.session
                    //nextAction && nextAction(data);
                    //nextAction && nextAction(data.reservation_no);
                }
            })
            .catch(()=>{
                //console.log("7 ToretaApi PayStripeWithIntent, error:");
                createPaymentErrorHandle(stripe, failAction);
            });
    };
    const GetFinishReserve = async (): Promise<any> => {
        
        Actions.loadingControl(true);
        return await axios
            .get(`/api/${publicKey}/reserve/finish`)
            .then((response) => {
                //console.log("ToretaApi GetFinishReserve:", response.data);
                return response.data;
            })
            .catch((error) => {
                //console.error("ToretaApi GetFinishReserve:",error);
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    function addPreZero(num: number) {
        return ("00" + num).slice(-2);
    }
    const transformDateByStattTime = (date: string, starttime: number): string => {
        const hour = Math.floor(starttime / 3600);
        if (hour >= 24) {
            const newdate = moment(date).add(1, "days").format("YYYY-MM-DD");
            return newdate;
        } else {
            return date;
        }
    };
    const transformStattTime = (value: number | null): string => {
        if (!value) {
            return "";
        }
        const hour = Math.floor(value / 3600);
        if (currentLanguage === "zh-TW") {
            if (hour < 12 || hour >= 24) {
                return t("AM") + addPreZero(hour >= 24 ? hour - 24 : hour) + ":" + addPreZero(Math.floor((value % 3600) / 60));
            } else {
                return t("PM") + addPreZero(hour > 12 ? hour - 12 : 12) + ":" + addPreZero(Math.floor((value % 3600) / 60));
            }
        } else {
            if (hour < 12 || hour >= 24) {
                return addPreZero(hour >= 24 ? hour - 24 : hour) + ":" + addPreZero(Math.floor((value % 3600) / 60)) + t("AM");
            } else {
                return addPreZero(hour > 12 ? hour - 12 : 12) + ":" + addPreZero(Math.floor((value % 3600) / 60)) + t("PM");
            }
        }
    };
    const DisplayCustomername = (data: YoyakuBody) => {
        const country = shop.country;
        const company_country = shop.company_country;
        if (company_country === "jp" && country === "jp") {
            return `${data.last_name} ${data.first_name}` + (currentLanguage === "ja" ? ` (${data.last_name_reading} ${data.first_name_reading})`: '')
        } else if (country === "tw" || country === "jp") {
            return `${data.last_name} ${data.first_name}`;
        } else {
            return `${data.first_name} ${data.last_name}`;
        }
    };
    React.useEffect(() => {
        if (!shop) {
            GetShopDetail();
        }
        // eslint-disable-next-line
    }, [publicKey]);
    return {
        GetHolidaySetting,
        GetShopDetail,
        GetShopOther,
        GetEmptySetas,
        transformStattTime,
        AddReserve: AddReserveFull,
        AddReserveByStaff,
        GetReserveByStaff,
        AddReserveSripe,
        BeginPayStripe,
        //CloseReserveByStaff,
        GetFinishReserve,
        GetDebitAmount,
        GetStripePK,
        AddReserveSripeWithIntent,
        PayStripeWithIntent,
        GetStripeFund,
        FinishStripeFund,
        DisplayCustomername,
        ScrollTop,
        publicKey,
        transformDateByStattTime,
    };
};

export default ToretaApi;
