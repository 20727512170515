import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, FormControlLabel, Typography, TextField, InputLabel, MenuItem, FormControl, Select } from "@material-ui/core";
import { makeStyles, Theme, createStyles, withStyles } from "@material-ui/core/styles";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { useTranslation } from "react-i18next";
import { green } from "@material-ui/core/colors";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { YoyakuBody, PersonalInfo, PersonalInfoError, ShopDetail } from "../../common/interface";
import { GreenButton } from "../../Components";
import CountrySelect, { CountryList } from "./CountrySelectField";
import basecountry from "../../country.json";
import ToretaApi from "../../ToretaApi";
const phoneUtil = PhoneNumberUtil.getInstance();

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        required: {
            color: "red",
        },
    })
);
const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        "&$checked": {
            color: green[600],
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
type Props = {
    initValue: YoyakuBody;
    className: any;
    onNext: (data: PersonalInfo) => void;
};

function ValidateEmail(mail: string): boolean {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
        return true;
    }

    return false;
}

interface Step2PersonalInfo extends PersonalInfo {
    // phonecode: string;
    phoneiso: string;
}
const Step2: React.FunctionComponent<Props> = (props) => {
    const currentLanguage: string = useSelector((state: any) => state.lang);
    const classes = useStyles();
    const toretaApi = ToretaApi();
    const shop: ShopDetail = useSelector((state: any) => state.shop);
    const country = shop.country ? shop.country.toUpperCase() : null;
    const cretaInitValue = () => {
        return {
            last_name: "",
            first_name: "",
            last_name_reading: "",
            first_name_reading: "",
            phone: "",
            email: "",
            phoneiso: CountryList.find((a) => a.iso === country)?.iso,
            title: "Mr",
        } as Step2PersonalInfo;
    };
    const [personalInfo, setPersonalInfo] = React.useState<Step2PersonalInfo>(
        localStorage.getItem("personal")
            ? {
                  ...cretaInitValue(),
                  ...JSON.parse(localStorage.getItem("personal") as string),
              }
            : cretaInitValue()
    );
    const [errors, setErrors] = React.useState<PersonalInfoError>({
        last_name: false,
        first_name: false,
        phone: false,
        email: false,
        phonecode: false,
    });

    const regexReading = new RegExp("^[一-龯ぁ-ゔァ-ヴーｧ-ﾝﾞﾟ々〆〤ヶａ-ｚＡ-Ｚa-zA-Z\\s]*$");

    const handleTextChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if(key === "last_name_reading" || key === "first_name_reading"){
            if(event.target.value && !regexReading.test(event.target.value)){
                event.target.value = personalInfo[key];
            }
        }
        const newvalue = { ...personalInfo, [key]: event.target.value as string };
        setPersonalInfo(newvalue);
    };
    const handleCountrySelectChange = (value: string) => {
        const newvalue: Step2PersonalInfo = { ...personalInfo, phoneiso: value };
        setPersonalInfo(newvalue);
    };
    const [checked, setChecked] = React.useState(localStorage.getItem("saveBrowser") ? JSON.parse(localStorage.getItem("saveBrowser") as string) : false);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const [getDM_OPTIN,setDM_OPTIN]=useState("RESERVE_CONFIRMATION.DM_OPTIN");
    const [checked1, setChecked1] = React.useState(props.initValue.optin_dm ? props.initValue.optin_dm : false);
    const handleChecked1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked1(event.target.checked);
    };
    
    const { t } = useTranslation();
    useEffect(() => {
        setDM_OPTIN("RESERVE_CONFIRMATION.DM_OPTIN");
        if(shop.company_country=="jp"){
            setDM_OPTIN("RESERVE_CONFIRMATION.DM_OPTIN_JP");
        }
        toretaApi.ScrollTop();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        check();
        // eslint-disable-next-line
    }, [personalInfo]);
    const check = () => {
        const currentError = {
            last_name: false,
            first_name: false,
            phone: false,
            email: false,
            phonecode: false,
        };
        if (personalInfo.email && !ValidateEmail(personalInfo.email)) {
            currentError.email = true;
        }
        if (personalInfo.email === "") {
            return false;
        }
        if (personalInfo.phone) {
            try {
                const number = phoneUtil.parseAndKeepRawInput(personalInfo.phone, personalInfo.phoneiso);
                if (!phoneUtil.isValidNumber(number)) {
                    currentError.phone = true;
                }
            } catch (e) {
                currentError.phone = true;
            }
        }
        setErrors(currentError);
    };

    const canGoNext = () => {
        try {
            if (personalInfo.last_name === "") {
                return false;
            }
            if (personalInfo.first_name === "") {
                return false;
            }
            if (personalInfo.email                                                                                                                                                                                                                                                                                       === "") {
                return false;
            }
            if (personalInfo.phone === "" || errors.email) {
                return false;
            }

            if (personalInfo.email === "") {
                return false;
            }

            if (!personalInfo.phoneiso) {
                return false;
            }
            if (personalInfo.phoneiso === "") {
                return false;
            }
            if (errors.email) {
                return false;
            }
            if (errors.phone) {
                return false;
            }
        } catch (e) {
            return false;
        }
        return true;

    };
    const handleSelectChange = (key: string) => {
        return async (event: React.ChangeEvent<{ value: unknown }>) => {
            const newvalue = { ...personalInfo, [key]: event.target.value as string };
            setPersonalInfo(newvalue);
        };
    };
    // const nextdisabled = !canGoNext();
    const finishWork = () => {
        if (personalInfo.phoneiso === null || personalInfo.phoneiso === "") {
            return;
        }
        if (checked) {
            localStorage.setItem("personal", JSON.stringify(personalInfo));
            localStorage.setItem("saveBrowser", JSON.stringify(checked));
        }
        const country = basecountry.find((a) => a.iso === shop.country.toUpperCase());
        let country_code = "";
        const number = phoneUtil.parseAndKeepRawInput(personalInfo.phone, personalInfo.phoneiso);
        const phone = phoneUtil.format(number, PhoneNumberFormat.E164);
        if (number && number.getCountryCode()) {
            const tempcode = number.getCountryCode();
            if (tempcode) {
                country_code = `${tempcode}`;
            }
        }
        let value: PersonalInfo = {
            ...personalInfo,
            phone: personalInfo.phone,
            country_code: country_code,
            phoneview: phone,
        };
        if (country) {
            if (country.iso === personalInfo.phoneiso) {
                value.phoneview = phoneUtil.format(number, PhoneNumberFormat.NATIONAL).replaceAll(/ /g, "");
            }
        }
        props.initValue.optin_dm = checked1;
        props.onNext(value);
    };
    let nameField = (
        <>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label={t("FIRST_NAME")}
                    rows={4}
                    defaultValue={personalInfo.first_name}
                    variant="outlined"
                    onChange={handleTextChange("first_name")}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label={t("LAST_NAME")}
                    rows={4}
                    defaultValue={personalInfo.last_name}
                    variant="outlined"
                    onChange={handleTextChange("last_name")}
                />
            </Grid>
        </>
    );
    if (shop.country === "tw") {
        nameField = (
            <>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label={t("LAST_NAME")}
                        rows={4}
                        defaultValue={personalInfo.last_name}
                        variant="outlined"
                        onChange={handleTextChange("last_name")}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label={t("FIRST_NAME")}
                        rows={4}
                        defaultValue={personalInfo.first_name}
                        variant="outlined"
                        onChange={handleTextChange("first_name")}
                    />
                </Grid>
            </>
        );
    }
    let nameFieldReading = (<></>);
    if (shop.country === "jp") {
        nameField = (
            <>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label={t("LAST_NAME")}
                        rows={4}
                        defaultValue={personalInfo.last_name}
                        variant="outlined"
                        onChange={handleTextChange("last_name")}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label={t("FIRST_NAME")}
                        rows={4}
                        defaultValue={personalInfo.first_name}
                        variant="outlined"
                        onChange={handleTextChange("first_name")}
                    />
                </Grid>
            </>
        );
        if(shop.company_country === "jp" && currentLanguage === "ja"){
            nameFieldReading = (
                <>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={t("LAST_NAME_READING")}
                            rows={4}
                            defaultValue={personalInfo.last_name_reading}
                            variant="outlined"
                            onChange={handleTextChange("last_name_reading")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={t("FIRST_NAME_READING")}
                            rows={4}
                            defaultValue={personalInfo.first_name_reading}
                            variant="outlined"
                            onChange={handleTextChange("first_name_reading")}
                        />
                    </Grid>
                </>
            );
        }
    }
    return (
        <>
            <Box my={2}>
                <Typography variant="body1" component="h2" gutterBottom>
                    <Box fontWeight="fontWeightBold" m={0}>
                        {t("PERSONAL_INFO.EXPLAIN")}
                        <span className={classes.required}> {t("REQUIRED")}</span>:
                    </Box>
                </Typography>
            </Box>
            <Grid container className={props.className} spacing={2}>
                <Grid item xs={12}>
                    <FormControl variant="outlined">
                        <InputLabel>{t("PERSONAL_INFO.TITLE")}</InputLabel>
                        <Select value={personalInfo.title} onChange={handleSelectChange("title")} label={t("TITLE")}>
                            <MenuItem key={"Mr"} value={"Mr"}>
                                {t("PERSONAL_INFO.MR")}
                            </MenuItem>
                            <MenuItem key={"Mrs"} value={"Mrs"}>
                                {t("PERSONAL_INFO.MRS")}
                            </MenuItem>
                            <MenuItem key={"Ms"} value={"Ms"}>
                                {t("PERSONAL_INFO.MS")}
                            </MenuItem>
                            <MenuItem key={"Mdm"} value={"Mdm"}>
                                {t("PERSONAL_INFO.MDM")}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {nameField}
                {nameFieldReading}
                <Grid item xs={12} sm={6}>
                    <CountrySelect value={personalInfo.phoneiso} onChange={handleCountrySelectChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        error={errors.phone}
                        fullWidth
                        label={t("MOBILE")}
                        rows={4}
                        defaultValue={personalInfo.phone}
                        variant="outlined"
                        onChange={handleTextChange("phone")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        error={errors.email}
                        fullWidth
                        label={t("EMAIL")}
                        rows={4}
                        defaultValue={personalInfo.email}
                        variant="outlined"
                        helperText=""
                        onChange={handleTextChange("email")}
                    />
                </Grid>
            </Grid>
            <Box mt={4} textAlign="center">
                <FormControlLabel 
                    control={<GreenCheckbox checked={checked1} onChange={handleChecked1Change} name="checked" />}
                    label={<span style={{ fontWeight: "bold" }}> {t(getDM_OPTIN)}</span>}
                /><br/>
                <FormControlLabel
                    control={<GreenCheckbox checked={checked} onChange={handleChange} name="checked" />}
                    label={<span style={{ fontWeight: "bold" }}> {t("PERSONAL_INFO.SAVE_BROWSER")}</span>}
                />
                <Typography variant="body1" component="h3" gutterBottom>
                    <Box m={0} style={{ lineHeight: "1" }}>
                        {t("PERSONAL_INFO.SAVE_BROWSER_ATTENTION")}
                    </Box>
                </Typography>
            </Box>
            <Box mt={2} textAlign="center">
                <GreenButton disabled={!canGoNext()} variant="contained" color="primary" onClick={finishWork}>
                    {t("HOME.BUTTON_NEXT_TEXT")}
                </GreenButton>
            </Box>
        </>
    );
};
export default Step2;
