import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    Box,
    Button,
    Grid,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Typography,
    TextField,
    Card,
    CardContent,
    CardActions,
    Avatar,
    CardMedia,
    Collapse,
    Modal,
    Backdrop,
    Fade,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Linkify from 'react-linkify';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import moment from "moment";
import ToretaApi from "../../ToretaApi";
import {
    ShopDetail,
    YoyakuBody,
    YoyakuCourse,
    ToretaReservationSearchBody,
    ToretaReservationsDaysResponse,
    ShopInfo,
    Course,
    Table,
    HolidaySettings,
} from "../../common/interface";
import { DatePicker, GreenButton } from "../../Components";
import { ExpandMore, Image } from "@material-ui/icons";
import ContentReadmore, { ShowReadMoreButtons, ResetReadMoreButtons } from "./ContentReadmore";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        required: {
            color: "red",
        },   
        card: {
            border: "none",
            boxShadow: "none"
        },
        cardHovered: {
            transform: "scale3d(10, 10, 1)"
        },
        media: {
            minWidth: "120px",
            minHeight: "120px",
            backgroundSize: "contain",
            // paddingTop: "56.25%", // 16:9
        },
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        img: {
          outline: "none"
        },
        description:{
            'display':'-webkit-box',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': '6',
            'overflow': 'hidden',
        }
    })
    
);

type Props = {
    course: YoyakuCourse[];
    initValue: YoyakuBody;
    className: any;
    onNext: (data: YoyakuBody) => void;
};
var seatTypeLanguage: { [id: string] : string; } = {};
interface CanSelectTable extends Table {
    disabled: boolean;
}
const Step1: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const toretaApi = ToretaApi();
    const { t } = useTranslation();
    const shop: ShopDetail = useSelector((state: any) => state.shop);
    const shopinfo: ShopInfo = useSelector((state: any) => state.shopinfo);
    const [form, setForm] = React.useState(props.initValue);
    const [
        seatInfos,
        setSeatInfos,
    ] = React.useState<ToretaReservationsDaysResponse>({});
    //placeholde
    const [selectedAdultsPlaceholder,setSelectedAdultsPlaceholder] = useState<boolean>(false);
    const [holidaySetting,setHolidaySetting] = useState<HolidaySettings[]>([]);
    
    const [selectedTimePlaceholder,setSelectedTime]= useState<boolean>(false);
    const [courses, setCourses] = React.useState(props.course);

    var firtLoad =true;
    useEffect(()=>{
        //placeholder Date
        // const placeDate = document.querySelector(".MuiInputBase-input.Mui-disabled") as HTMLDivElement;
        // if(selectedLoadDate===true){
        //     placeDate.style.color = "#aeaeae"; 
        // }      
        //Css BtnDelCourse
        // const buttonDelCourse =document.querySelector(".MuiButton-containedPrimary") as HTMLDivElement;
        // buttonDelCourse.style.display = "FLEX";
        // buttonDelCourse.style.justifyContent ="CENTER";
        // buttonDelCourse.style.flexDirection ="COLUMN";
        
        
        //placeholder Adults
        const placeAdults = document.querySelector("#adult") as HTMLDivElement;
        if(placeAdults){
            if(selectedAdultsPlaceholder ===false && form.adults==0 && form.children==0){            
                placeAdults.textContent=t("RESERVE_INFO.SELECT_ADULTS");
                // placeAdults.textContent=("CHILDREN");
                placeAdults.style.color = "#aeaeae";

            }
            if(selectedAdultsPlaceholder === true && form.adults > 0){
                
                placeAdults.textContent=form.adults + t("PEOPLE");
                placeAdults.style.color = "Black";

            }
        }
        if(form.adults==0 && form.children==0){
            firtLoad =true;
        }

        //placeholder Time
        const placeTime = document.getElementById('time') as HTMLDivElement;   
        if(selectedTimePlaceholder ===false && form.adults==0 && form.children==0){
            placeTime.style.color = "#aeaeae";
        }

        courses?.map(({ id, count }, index) => {
            const placeCourses = document.querySelector("#number_" + index) as HTMLDivElement;
            if(placeCourses){
                if(count==0){                                            
                    placeCourses.textContent = shop.isMultiMealCourse ? t("RESERVE_INFO.NOTE_CHOOSE") : t("RESERVE_INFO.NOTE_NOTSELECT");
                    placeCourses.style.color = "#aeaeae";
                }
                else{
                    placeCourses.textContent= shop.isMultiMealCourse ? count + t("PEOPLE") : t("RESERVE_INFO.NOTE_SELECT");
                    placeCourses.style.color = "black";
    
                }
            }
        });
        ShowReadMoreButtons();
        window.addEventListener('resize', ShowReadMoreButtons);
    })

    const secondsToHHmm = (d: number) => {
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        return ((h >= 10 ? h.toString() : "0" + h.toString()) + (m >= 10 ? m.toString() : "0" + m.toString()));   
    }

    const secondsToHH_mm = (d: number) => {
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        return ((h >= 10 ? h.toString() : "0" + h.toString()) + ":" + (m >= 10 ? m.toString() : "0" + m.toString()));   
    }

    const secondsToShortDate = (d: number) => {
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        return ((h >= 10 ? h.toString() : "0" + h.toString()) + (m >= 10 ? m.toString() : "0" + m.toString()));   
    }

    const GetHolidaySetting = async()=>{
        const data = await toretaApi.GetHolidaySetting(shop.country);
        setHolidaySetting(data);
    }
    
    useEffect(() => {
        GetHolidaySetting();
    }, [shop]);

    const arrWkDays = [
        {code: 0, value: 'SUN'},
        {code: 1, value: 'MON'},
        {code: 2, value: 'TUE'},
        {code: 3, value: 'WED'},
        {code: 4, value: 'THU'},
        {code: 5, value: 'FRI'},
        {code: 6, value: 'SAT'},
    ];

    const usedCourses = React.useMemo(() => {
        if (!shopinfo.courses) {
            return [];
        }
        if (!form.target_date) {
            return [];
        }
        const isInExcludeDate = (course: Course) => {
            //是否隱藏餐點
            try {
                const today = form.target_date;
                let totime = form.start_time;
                if (!today) { //未指定入席日
                    return true;
                }
                if (!totime) { //未指定入席時間
                    return true;
                }

                //add enable_lunch/enable_dinner filter
                const m = moment( today );
                const ohours = shopinfo.openhours? shopinfo.openhours: [];

                let lunch_start = ohours[m.weekday()].lunch_start ?? 86400;
                let lunch_end = ohours[m.weekday()].lunch_end ?? 0;
                let dinner_start = ohours[m.weekday()].dinner_start ?? 86400;
                let dinner_end = ohours[m.weekday()].dinner_end ?? 0;
                let lunch_cutoff = ohours[m.weekday()].lunch_cutoff ?? 0;
                let dinner_cutoff = ohours[m.weekday()].dinner_cutoff ?? 0;
                dinner_end = (dinner_end > 0 && dinner_end < dinner_start ? dinner_end + 86400 : dinner_end);
                totime = (totime < lunch_start && totime + 86400 < dinner_end) ? totime + 86400 : totime;

                if(!(totime >= lunch_start && totime <= lunch_end)
                    && !(totime >= dinner_start && totime <= dinner_end)
                ){
                    return true;
                }

                if( 
                    totime >= lunch_start && 
                    totime <= lunch_end
                ){  
                    if(course.enable_lunch !== true){
                        //lunch time & course is not lunch
                        return true;
                    }
                }

                if(course.enable_lunch == true && course.enable_dinner !== true
                    && lunch_cutoff > 0 && totime > lunch_cutoff){
                    return true;
                }
                if(course.enable_dinner == true
                    && dinner_cutoff > 0 && totime > dinner_cutoff){
                    return true;
                }
                
                if( 
                    totime >= dinner_start && 
                    totime <= dinner_end 
                ){
                    if(course.enable_dinner !== true){
                        // dinner time , course is not dinner
                        return true;
                    }
                }

                //[非供餐期間]的檢查
                if (
                    course.exclude_date_from === null &&
                    course.exclude_date_to === null
                ) {
                    return false;//沒有[非供餐期間]的設定-->顯示餐點
                } else if (
                    course.exclude_date_from === null &&
                    course.exclude_date_to &&
                    course.exclude_date_to >= today
                ) {
                    return true; //[非供餐期間]截止日 晚於指定日期 (無起始日)-->隱藏餐點
                } else if (
                    course.exclude_date_to === null &&
                    course.exclude_date_from &&
                    course.exclude_date_from <= today
                ) {
                    return true;//[非供餐期間]起始日 早於指定日期 (無截止日)-->隱藏餐點
                } else if (course.exclude_date_from && course.exclude_date_to) {
                    if (
                        course.exclude_date_from <= today &&
                        course.exclude_date_to >= today
                    ) {
                        return true; //指定日期落在停止供餐期間-->隱藏餐點
                    }else{
                        return false; //指定日期不在停止供餐期間-->顯示餐點
                    }
                }
            } catch (e) {
                //console.error(e);
            }
            return true; //預設隱藏餐點
        };

        const validCourse = (course: Course) => {
            const today = form.target_date;
            if (!today) {
                return false;//無指定入席日則不顯示餐點清單
            }
            
            if (shop.onCourse && !shop.onCourse.includes(course.id)) {
                return false;// 如果餐點不在允許清單內就排除之
            }

            if (course.start_date === null && course.end_date === null) {
                return !isInExcludeDate(course);//餐點無設定供應期間, 則以午晚餐+停止供餐設定判斷
            } else if (course.end_date === null) {
                if (course.start_date && course.start_date <= today) {
                    return !isInExcludeDate(course);//若餐點有起始日期, 則以午晚餐+停止供餐設定判斷
                }
            } else {
                if (
                    course.start_date &&
                    course.end_date &&
                    today >= course.start_date &&
                    today <= course.end_date
                ) {
                    return !isInExcludeDate(course);//若餐點落在供應期間, 則還要加上午晚餐+停止供餐設定判斷
                }
            }
            return false;
        };

        //return shopinfo.courses.filter(validCourse);
        let result = shopinfo.courses.filter(validCourse);
        let courseInfo = [] as YoyakuCourse[];
        result.map((x) => {
            let invalid = false;
            if(form.adults <= 0)
            {
                invalid = true;
            }
            else{
                if(x.numberOfPersonsMin && x.numberOfPersonsMax
                    && (form.adults < x.numberOfPersonsMin || form.adults > x.numberOfPersonsMax)){
                    invalid = true;
                }
            }

            if(shop.needTable && form.table_category && x.seatTypes && !x.seatTypes.split(', ').includes(form.table_category))
            {
                invalid = true;
            }

            if(form.start_time && x.startTimes && !x.startTimes.split(', ').includes(secondsToHHmm(form.start_time)))
            {
                invalid = true;
            }
            
            // if(form.target_date && x.servicePeriodFrom && x.servicePeriodTo 
            //     && !(
            //         (new Date(form.target_date || "") >= new Date(x.servicePeriodFrom || -8640000000000000)) 
            //         && (new Date(form.target_date || "") <= new Date(x.servicePeriodTo || 8640000000000000))
            //     ))
            // {
            //     invalid = true;
            // }

            if(form.target_date && x.exceptions && x.exceptions.length > 0 
                && x.exceptions.some(ex => (
                    (new Date(form.target_date || "") >= new Date(ex.fromDate || -8640000000000000)) 
                    && (new Date(form.target_date || "") <= new Date(ex.toDate || 8640000000000000))
                )))
            {                    
                invalid = true;
            }

            if(form.target_date && x.cutoffTimeFrom != null && x.cutoffTimeFrom != "" && x.cutoffTimeTo){
                const target_date = new Date(form.target_date + " " + secondsToHH_mm(form.start_time || 0));
                const cutoffDate = new Date(new Date(new Date(target_date.setDate(target_date.getDate() - (+x.cutoffTimeFrom)))
                    .setHours(+x.cutoffTimeTo.substring(0,2)))
                    .setMinutes(+x.cutoffTimeTo.substring(2)));
                if(new Date() > cutoffDate){ 
                    invalid = true;
                }
            }

            if(form.target_date && x.serviceDays){
                let included = false;
                const serviceDaysSetting = (x.serviceDays || "").split(', ');
                if(serviceDaysSetting && serviceDaysSetting.length > 0){
                    const target_date = new Date(form.target_date);
                    const dayWk = (arrWkDays.find(w => w.code == target_date.getDay())?.value || "")
                    if(serviceDaysSetting.some(s => s == dayWk)){
                        included = true;
                    }
                    else{
                        if(holidaySetting && holidaySetting.length > 0){
                            if(!included && serviceDaysSetting.some(s => s == "HOL") 
                                && holidaySetting.some(hd => new Date(hd.date).toISOString().substring(0,10) == target_date.toISOString().substring(0,10))
                            ){
                                included = true;
                            }

                            if(!included && serviceDaysSetting.some(s => s == "WKDBEFORE")){
                                const dayAfter = new Date(new Date(target_date).setDate(target_date.getDate() + 1));
                                if(target_date.getDay() != 0 && target_date.getDay() != 6
                                    && holidaySetting.some(hd => new Date(hd.date).toISOString().substring(0,10) == dayAfter.toISOString().substring(0,10)
                                    && !holidaySetting.some(hd => new Date(hd.date).toISOString().substring(0,10) == target_date.toISOString().substring(0,10)))
                                )
                                {
                                    included = true;
                                }
                            }
                            
                            if(!included && serviceDaysSetting.some(s => s == "HOLBEFORE")){
                                const dayAfter = new Date(new Date(target_date).setDate(target_date.getDate() + 1));
                                if((target_date.getDay() == 0 || target_date.getDay() == 6
                                        || holidaySetting.some(hd => new Date(hd.date).toISOString().substring(0,10) == target_date.toISOString().substring(0,10)))
                                    && holidaySetting.some(hd => new Date(hd.date).toISOString().substring(0,10) == dayAfter.toISOString().substring(0,10))
                                )
                                {
                                    included = true;
                                }
                            }
                        }
                    }
                }
                if(!included){
                    invalid = true;
                }
            }
            if(!invalid){
                const count = form?.courses?.find(y => y.id == x.id) ? form?.courses?.find(y => y.id == x.id)?.count : 0;
                courseInfo?.push({ 
                    id: x.id, 
                    count: count as number,
                    authorizationHoldAmount: (shopinfo?.courses?.find(y => y.id == x.id)?.authorizationHoldAmount) as number | null
                });
            }
            else{
                //alert("invalid")
            }
        });
        setCourses(courseInfo);
        return result || [];
         
    }, [shopinfo, shop, form.adults, form.children, form.target_date, form.start_time, form.table_category]);
    
    const setDisplaySeats=()=>{
        seatTypeLanguage["none"]="RESERVE_INFO.ITEM_NO_PREFERENCE";
        seatTypeLanguage["counter"]="RESERVE_INFO.ITEM_COUNTER_SEAT";
        seatTypeLanguage["private"]="RESERVE_INFO.ITEM_PRIVATE_ROOM";
        seatTypeLanguage["table"]="RESERVE_INFO.ITEM_TABLE_SEAT";
        seatTypeLanguage["outdoor_seat"]="RESERVE_INFO.ITEM_OUTDOOR_SEAT";
        seatTypeLanguage["tatami_seat"]="RESERVE_INFO.ITEM_TATAMI_SEAT"; 
        seatTypeLanguage["bar_counter"]="RESERVE_INFO.ITEM_BAR_COUNTER"; 
        seatTypeLanguage["takeaway"]="RESERVE_INFO.ITEM_TAKEAWAY"; 
        seatTypeLanguage["semi_private"]="RESERVE_INFO.ITEM_SEMI_PRIVATE"; 
        seatTypeLanguage["sofa_seat"]="RESERVE_INFO.ITEM_SOFA_SEAT"; 
        seatTypeLanguage["horigotatsu"]="RESERVE_INFO.ITEM_HORIGOTATSU";     
    }
    const getDisplaySeats=(key:any, defV:string)=>{
        shop.seat_type = form.table_category==null?"":form.table_category;
        try{ 
        var lang= t(seatTypeLanguage[key]);
        if(lang!=undefined && lang!=null && lang.length>0) 
        {
            return lang;
        }
        return defV;
        }catch{
            return defV;
        }
    }
    const seatRefresh = async (newvalue: YoyakuBody) => {
        const start_date = moment()
            .add(shop.accept_limit_day, "days")
            .format("YYYY-MM-DD");
        const end_date = moment()
            .add(shop.allowed_reservation_days, "days")
            .format("YYYY-MM-DD");
        const querydata: ToretaReservationSearchBody = {
            id: "",
            start_date: start_date,
            end_date: end_date,
            seats: newvalue.adults + newvalue.children,
        };

        await toretaApi.GetEmptySetas(querydata).then((info) => {
            let match = false;
            if (info.slots) {
                if (newvalue.target_date) {
                    const dateslot = info.slots.find(
                        (a: any) => a.date === newvalue.target_date
                    );
                    if (dateslot) {
                        const timeseat = dateslot.slots.find(
                            (a: any) => a.start_time === newvalue.start_time
                        );
                        if (timeseat) {
                            if (newvalue.table_category !== null) {
                                const tablecategories = timeseat?.options[0].table_categories.find(
                                    (a: any) => a === newvalue.table_category
                                );
                                if (tablecategories) {
                                    match = true;
                                }
                            } else {
                                match = true;
                            }
                        }
                    }
                }
                if (!match) {
                    newvalue.start_time = null;
                    newvalue.table_category = null;
                    if (info.slots[0]) {
                        newvalue.target_date = info.slots[0].date;
                    }
                }
            }
            setSeatInfos(info);
        });
        setForm(newvalue);
    };
    const handleNumberChangeAndCallSeats = (key: string) => {
        return async (event: React.ChangeEvent<{ value: unknown }>) => {
            const newvalue = {
                ...form,
                [key]: event.target.value as number,
                start_time: null,
                table_category: null,
                courses: [{ id: "", count: 0, authorizationHoldAmount: null }],
            };
            if(key ==="adults"){
                const placeAdults = document.querySelector("#adult")as HTMLDivElement;
                if(placeAdults){
                    placeAdults.textContent=(event.target.value as string) + t("PEOPLE");
                    placeAdults.style.color = "Black";
                }
                setSelectedAdultsPlaceholder(true);                
            }
            
            await seatRefresh(newvalue);
        };
    };
    const handleTextChange = (key: string) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newvalue = { ...form, [key]: event.target.value as string };
        setForm(newvalue);
    };
    const handleSelectTextChange = (key: string) => {
        return async (event: React.ChangeEvent<{ value: unknown }>) => {
            const newvalue = { ...form, [key]: event.target.value as string };
            setForm(newvalue);
        };
    };
    const selectDisabled = !seatInfos.slots || seatInfos.slots.length === 0;
    const [errMessage,setErrMessage]= useState<string | null>(null);
    const check = () => {
        if (form.adults + form.children === 0) {
            return false;
        }
        if (!form.target_date) {
            return false;
        }
        if (!form.start_time) {
            return false;
        }
        if (shop.needTable) {
            if (!form.table_category || form.table_category === "") {
                return false;
            }
        }

        if (shop.needCourse) {
            if (!courses || courses.filter((a) => a.id !== "").length === 0) {
                return false;
            }
        }

        return true;
    };
    const goNext = check();
    const finishWork = () => {
        const sum = courses.reduce((sum, current) => sum + current.count, 0);
        console.log("Num: " + (form.adults + form.children));
        console.log("Sum: " + sum);
        console.log("isMultiMealCourse: " + shop.isMultiMealCourse.toString());
        if(shop.isMultiMealCourse){
            if(shop.isMultiNumberOfPerson){
                if(shop.needCourse && (form.adults + form.children) > sum){
                    setErrMessage(t("RESERVE_INFO.MSG_CHECK_MULTI_a_A2"));
                    return;
                }
            }
            else{
                if(shop.needCourse && (form.adults + form.children) != sum){
                    setErrMessage(t("RESERVE_INFO.MSG_CHECK_MULTI_a_A1"));
                    return;
                }
                if(shop.needCourse == null && (form.adults + form.children) < sum){
                    setErrMessage(t("RESERVE_INFO.MSG_CHECK_MULTI_b_A1"));
                    return;
                }
            }
        }
        else{
            if(shop.needCourse && sum != 1){
                setErrMessage(t("RESERVE_INFO.MSG_CHECK_MULTI_a_B"));
                return;
            }
            if(shop.needCourse == null && sum > 1){
                setErrMessage(t("RESERVE_INFO.MSG_CHECK_MULTI_b_B"));
                return;
            }
        }

        form.seats = form.adults + form.children;
        // 處理結束時間
        const endinfo = getTableEndtime();
        if (endinfo) {
            if (endinfo.has_limit_time) {
                form.end_time = endinfo.end_time;
            } else {
                delete form.end_time;
            }
        }
        const tempcourse: any = {};
        if (courses) {
            courses.forEach((course) => {
                if (tempcourse[course.id]) {
                    tempcourse[course.id] += course.count;
                } else {
                    tempcourse[course.id] = course.count;
                }
            });
            const courselist : YoyakuCourse[] = [];
            for (let key in tempcourse) {
                courselist.push({ 
                    id: key,
                    count: tempcourse[key],
                    authorizationHoldAmount: (usedCourses.find(x => x.id == key)?.authorizationHoldAmount) as number | null
                });
            }
            if (courselist.length <= 0) {
                courselist.push({ 
                    id: "", 
                    count: 0 ,
                    authorizationHoldAmount: null,
                });
            }

            form.courses = courselist.filter(x => x.count > 0);
        }
        // 整理菜單
        props.onNext(form);
    };
    const getTableEndtime = () => {
        try {
            if (seatInfos.slots) {
                const timetable = seatInfos.slots
                    .find((a) => a.date === form.target_date)
                    ?.slots.find((a) => a.start_time === form.start_time);
                if (timetable) {
                    if(form.table_category == null){
                        return timetable.options[0];
                    }
                    return timetable.options.find(o=>o.table_categories.includes(form.table_category||""));
                }
            }
            return null;
        } catch (e) {
            return null;
        }
    };
    const tableSeats =["private","table","counter","outdoor_seat","tatami_seat","semi_private","horigotatsu","sofa_seat","bar_counter","none","takeaway"];
    const getTableCategories = (): CanSelectTable[] => {
        let result: string[] | undefined = [];
        try {
            if (seatInfos.slots) {
                const timetable = seatInfos.slots
                    .find((a) => a.date === form.target_date)
                    ?.slots.find((a) => a.start_time === form.start_time);
                if (timetable) {
                    timetable.options.forEach(option => {
                        result= result?.concat(option.table_categories);
                    });
                }
            }

            let usedTable = shopinfo.tables?.map((table) => {
                const disabled = !(result && result?.includes(table.key_name));
                return {
                    ...table,
                    disabled,
                };
            });
        /*
        if(usedTable){
            usedTable.push({key_name: 'bar_counter', display_name: 'Counter Seat'});
            usedTable.push({key_name: 'takeaway', display_name: 'Counter Seat'});
            usedTable.push({key_name: 'semi_private', display_name: 'Counter Seat'});
            usedTable.push({key_name: 'sofa_seat', display_name: 'Counter Seat'});
            usedTable.push({key_name: 'horigotatsu', display_name: 'Counter Seat'});
        }
        */
        if(usedTable){
            for (const tbl of usedTable) {
                tbl.index = tableSeats.indexOf(tbl.key_name);
            }
            usedTable = usedTable.sort((a, b) => a.index - b.index);
        }
        return usedTable || [];

        } catch (e) {
            const usedTable = shopinfo.tables?.map((table) => {
                return {
                    ...table,
                    disabled: true,
                };
            });
            return usedTable || [];
        }
    };
    const addCourse = () => {
        form.courses?.push({ id: "", count: 0, authorizationHoldAmount: null });
        setForm({ ...form });
    };

    const deleteCourse = (idx:Number)=>{
        form.courses =form.courses?.filter((item,index)=>{
        return index!==idx?true:false
       })
       setForm({ ...form });
    }


    // eslint-disable-next-line
    const canSelectTableCategories = React.useMemo(() => getTableCategories(), [
        seatInfos,
        form,
        getTableCategories,
    ]);
    // eslint-disable-next-line
    const canSelectDays = React.useMemo(() => {
        return seatInfos.slots?.map((a) => a.date);
        // eslint-disable-next-line
    }, [seatInfos, form]);
    const showTable = shop.needTable;
    const showMenu = shop.needCourse || shop.needCourse ===null ;
    const firstPeopleCount = !shop.accept_children
        ? t("PARTY_SIZE")
        : t("ADULTS");
    const seats = form.adults + form.children;
    interface Map {
        [key: string]: any;
        [index: number]: any;
    }
    const dicCurrency:Map={
        "SGD":"S$",
        "TWD":"NT$",
        "MYR":"RM",
        "THB":"฿",
        "JPY":"¥",
    }
    const getCurrency = (key: string) =>{
        var c= Object.keys(dicCurrency).filter((val,ind) => {return val==key;});
        if(c.length>0){
            return dicCurrency[key];
        }
        return key;
    }
    const formatter = new Intl.NumberFormat('ja-JP', {
        //style: 'currency',
        currency: 'JPY',
      });
    const formatterEn = new Intl.NumberFormat('en-US', {
        currency: 'USD',
     });
    const getCurrencyAmt = (amt: number) =>{
        if(amt==null) return '0';
        if(shop.currency=="JPY"){
            return formatter.format(Math.trunc(amt));
        }
        return formatterEn.format(amt);
    }
    useEffect(() => {
        toretaApi.ScrollTop();
        toretaApi.GetShopOther();
        if (form.adults + form.children > 0) {
            seatRefresh(form);
        }
        setDisplaySeats();
        // eslint-disable-next-line
    }, []);


    const [expanded, setExpanded] = React.useState(-1);
    const handleExpandClick = (index: number) => {
        if(index === expanded){
            setExpanded(-1);
        }
        else{
            setExpanded(index);
        }
    };
  
    useEffect(() => {
        ResetReadMoreButtons();
        window.addEventListener('resize', ResetReadMoreButtons);
        ShowReadMoreButtons();
        window.addEventListener('resize', ShowReadMoreButtons);
    }, [form]);
    
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState("");
    const [imageNm, setImageNm] = useState("");
    const handleClose = () => {
        setOpen(false);
    };

    const handleImage = (value: string, name: string) => {
        setImage(value);
        setImageNm(name);
        setOpen(true);
    };

    const [width, height] = [window.screen.width, window.screen.height];
    const isMobile = Math.min(width, height) < 768;

    let courseDisplay = (
        <>
            {courses?.map(({ id, count }, index) => {
                const courseInfo = usedCourses.find(x => x.id == id);
                return (
                    <React.Fragment key={index} >
                        <Grid item xs={2} md={2} 
                                className="image-thumb"
                                container
                                spacing={0}
                                direction="column"
                                alignItems="flex-start"
                                justifyContent="flex-start">
                            <div style={{ display: "block"}}>
                                <img
                                src={courseInfo?.course_image}
                                alt={""}
                                onClick={(e) => handleImage(courseInfo?.course_image as string, courseInfo?.name as string)}
                                className="img"
                                style={{maxHeight: "120px", maxWidth: "120px", borderRadius: "4px" }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={7} md={7} 
                                className="grid-fill-remain">
                            <Grid item xs={12} md={12} 
                                style={{width: "inherit"}}>
                                <Typography style={{fontWeight: "bold"}} component="div">{courseInfo?.name}</Typography>
                                <br/>
                                <ContentReadmore data={courseInfo?.description} readMoreText={t("RESERVE_INFO.BUTTON_READ_MORE")} />
                            </Grid>
                        </Grid>
                        <Grid item xs={3} md={3}
                                className="price-thumb"
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="flex-start">
                            <div>
                                <span style={{fontSize: "1rem"}}>
                                    {courseInfo?.price ? (" " +(shop.company_country!="jp"?(shop.currency + courseInfo?.price)+" " : getCurrency(shop.currency)+" "+ getCurrencyAmt(parseFloat(courseInfo?.price))+" ")) : null}   
                                </span>
                                {courseInfo?.price ? (shop.company_country!="jp"?t("RESERVE_INFO.NOTE_TAX_EXCL") : t("RESERVE_INFO.NOTE_TAX_INCL")) : <br/>}
                            </div>
                            <div style={{paddingTop:"16px", width: '100%'}}>
                                <FormControl fullWidth variant="outlined">
                                    <Select
                                        id={"number_" + index}
                                        disabled={id === ""}
                                        placeholder={shop.isMultiMealCourse ? t("RESERVE_INFO.NOTE_CHOOSE") : t("RESERVE_INFO.NOTE_NOTSELECT")}
                                        value={count}
                                        style={{textAlign: "left"}}
                                        onChange={(event) => {
                                            const value = event.target.value as number;
                                            const coursesSet = [...courses]
                                            coursesSet[index].count = value
                                            setCourses(coursesSet);
                                            const placeCourses = document.querySelector("#number_" + index) as HTMLDivElement;
                                            if(placeCourses){
                                                if(value == 0){
                                                    placeCourses.textContent = shop.isMultiMealCourse ? t("RESERVE_INFO.NOTE_CHOOSE") : t("RESERVE_INFO.NOTE_NOTSELECT");
                                                    placeCourses.style.color = "#aeaeae";
                                                }
                                                else{
                                                    placeCourses.textContent= shop.isMultiMealCourse ? (count + t("PEOPLE")) : t("RESERVE_INFO.NOTE_SELECT");
                                                    placeCourses.style.color = "black";
        
                                                }
                                            }
                                        }}
                                    >
                                    {shop.isMultiMealCourse 
                                        ? new Array(seats + 1)
                                            .fill(0)
                                            .map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={index} >
                                                        <span style={{textAlign: "left", width: "100%"}}>{index}{t("RESERVE_INFO.FREE")}</span>
                                                    </MenuItem>
                                                );
                                            })
                                        : new Array(2)
                                            .fill(0)
                                            .map((item, index) => {
                                                return (
                                                    index == 0 ? 
                                                    <MenuItem key={0} value={0}>
                                                        <span style={{textAlign: "left", width: "100%", color: 'black'}}>
                                                            {t("RESERVE_INFO.NOTE_NOTSELECT")}
                                                        </span>
                                                    </MenuItem>
                                                    : <MenuItem key={1} value={1}>
                                                        <span style={{textAlign: "left", width: "100%", color: 'black'}}>
                                                            {t("RESERVE_INFO.NOTE_SELECT")}
                                                        </span>
                                                    </MenuItem>
                                                );
                                            })
                                    }
                                    </Select>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} spacing={2}>
                            <hr style={{borderStyle: 'dashed',  borderWidth: 1, borderColor: '#AEAEAE' }} />
                        </Grid>
                    </React.Fragment>
                );
            })}
        </>
    )

    let courseDisplayMobile = (
        <>
            {courses?.map(({ id, count }, index) => {
                const courseInfo = usedCourses.find(x => x.id == id);
                return (
                    <React.Fragment key={index} >
                        <Grid item xs={12} md={12}>
                            <Typography style={{fontWeight: "bold"}} component="div">{courseInfo?.name}</Typography>
                        </Grid>
                        <Grid item xs={12} md={12}
                                    container
                                    spacing={0}
                                    direction="row"
                        >
                            <Grid item xs={3} md={3} className="image-thumb"
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="flex-start"
                                    justifyContent="center">
                                <div style={{ display: "block", height: "120px", width: "120px" }}>
                                    <img
                                    src={courseInfo?.course_image}
                                    alt={""}
                                    onClick={(e) => handleImage(courseInfo?.course_image as string, courseInfo?.name as string)}
                                    className="img"
                                    style={{maxHeight: "120px", maxWidth: "120px", borderRadius: "4px" }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={7} md={7}
                                    className="grid-fill-remain"
                                    style={{width: "inherit"}}
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="flex-end"
                                    justifyContent="center">
                                <div>
                                    <span style={{fontSize: "1rem"}}>
                                        {courseInfo?.price ? (" " +(shop.company_country!="jp"?(shop.currency + courseInfo?.price)+" " : getCurrency(shop.currency)+" "+ getCurrencyAmt(parseFloat(courseInfo?.price))+" ")) : null}   
                                    </span>
                                    {courseInfo?.price ? (shop.company_country!="jp"?t("RESERVE_INFO.NOTE_TAX_EXCL") : t("RESERVE_INFO.NOTE_TAX_INCL")) : ""}
                                </div>
                                <div style={courseInfo?.price ?{paddingTop:"16px"}:{display:"flex", flex:"auto", alignItems:"center"}}>
                                    <FormControl  variant="outlined">
                                        <Select
                                            id={"number_" + index}
                                            disabled={id === ""}
                                            placeholder={shop.isMultiMealCourse 
                                                ? t("RESERVE_INFO.NOTE_CHOOSE") 
                                                : t("RESERVE_INFO.NOTE_NOTSELECT")
                                            }
                                            defaultValue={0}
                                            value={count}
                                            style={{width:"120px"}}
                                            onChange={(event) => {
                                                const value = event.target.value as number;
                                                const coursesSet = [...courses]
                                                coursesSet[index].count = value
                                                setCourses(coursesSet);

                                                const placeCourses = document.querySelector("#number_" + index) as HTMLDivElement;
                                                if(placeCourses){
                                                    if(value == 0){
                                                        placeCourses.textContent = shop.isMultiMealCourse ? t("RESERVE_INFO.NOTE_CHOOSE") : t("RESERVE_INFO.NOTE_NOTSELECT");
                                                        placeCourses.style.color = "#aeaeae";
                                                    }
                                                    else{
                                                        placeCourses.textContent= shop.isMultiMealCourse ? count + t("PEOPLE") : t("RESERVE_INFO.NOTE_SELECT");
                                                        placeCourses.style.color = "black";     
                                                    }
                                                }
                                            }}
                                        >
                                        {shop.isMultiMealCourse 
                                            ? new Array(seats + 1)
                                                .fill(0)
                                                .map((item, index) => {
                                                    return (
                                                        <MenuItem key={index} value={index} >
                                                            <span style={{textAlign: "left", width: "100%"}}>{index}{t("RESERVE_INFO.FREE")}</span>
                                                        </MenuItem>
                                                    );
                                                })
                                            : new Array(2)
                                                .fill(0)
                                                .map((item, index) => {
                                                    return (
                                                        index == 0 ? 
                                                            <MenuItem key={0} value={0}>
                                                                <span style={{textAlign: "left", width: "100%", color: 'black'}}>
                                                                    {t("RESERVE_INFO.NOTE_NOTSELECT")}
                                                                </span>
                                                            </MenuItem>
                                                            : <MenuItem key={1} value={1}>
                                                                <span style={{textAlign: "left", width: "100%", color: 'black'}}>
                                                                    {t("RESERVE_INFO.NOTE_SELECT")}
                                                                </span>
                                                            </MenuItem>
                                                    );
                                                })
                                        }
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                        <ContentReadmore data={courseInfo?.description} readMoreText={t("RESERVE_INFO.BUTTON_READ_MORE")} />
                        </Grid>
                        <Grid item xs={12} spacing={2}>
                            <hr style={{borderStyle: 'dashed',  borderWidth: 1, borderColor: '#AEAEAE' }} />
                        </Grid>
                    </React.Fragment>
                );
            })}
        </>
    )

    return (
        <>
            <Box my={2}>
                <Typography variant="body1" component="h2" gutterBottom>
                    <Box fontWeight="fontWeightBold" m={0}>
                        {t("RESERVE_INFO.EXPLAIN")}
                        <span className={classes.required}>
                            {" "}
                            {t("REQUIRED")}
                        </span>
                    </Box>
                </Typography>
            </Box>
            <Grid container className={props.className} spacing={2}>
                <Grid
                    item
                    xs={shop.accept_children ? 6 : 12}
                    md={shop.accept_children ? 2 : 4}
                >
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>{t("ADULTS")}</InputLabel>
                        <Select
                            id="adult"
                            value={form.adults}
                            //onChange={handleNumberChangeAndCallSeats("adults")}
                            //label={firstPeopleCount}
                            onChange={handleNumberChangeAndCallSeats("adults")}
                            label={t("ADULTS")}
                        >
                            
                            {Array.from(
                                Array(shop.restaurant_seats_max + 1).keys()
                            ).map((item, index) => {
                                if (
                                    index !== 0 &&
                                    index < shop.restaurant_seats_min
                                ) {
                                    return null;
                                }
                                return (
                                    <MenuItem key={index} value={index} >
                                        {index}
                                        {t("PEOPLE")}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                {shop.accept_children ? (
                    <Grid item xs={6} md={2}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>{t("CHILDREN")}</InputLabel>
                            <Select
                                value={form.children}
                                onChange={handleNumberChangeAndCallSeats("children")}
                                label={t("CHILDREN")}
                            >
                                {/* <MenuItem value={0}>Select CHILDREN</MenuItem> */}
                                {Array.from(
                                    Array(shop.restaurant_seats_max + 1).keys()
                                ).map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={index}>
                                            {index}
                                            {t("PEOPLE")}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                ) : (
                    <></>
                )}

                <Grid item xs={12} md={4}>
                    <DatePicker
                        
                        InputProps={{
                            placeholder: t("RESERVE_INFO.SELECT_DATE"),
                        }}
                        InputLabelProps={{ shrink: true }}
                        disabled={selectDisabled}
                        label={t("DATE")}
                        value={form.target_date}
                        enableDays={canSelectDays}
                        onChange={(value: string) => {
                            // const placeDate = document.querySelector(".MuiInputBase-input.Mui-disabled") as HTMLDivElement;
                            // placeDate.style.color = "#000000"; 
                            // setselectedLoadDate(true);
                            setForm({
                                ...form,
                                target_date: value,
                                start_time: null,
                                table_category: null,
                                courses: [{ id: "", count: 0, authorizationHoldAmount: null }],
                            });
                        }}
                        inputVariant="outlined"
                    ></DatePicker>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl
                        fullWidth
                        disabled={selectDisabled}
                        variant="outlined"
                    >
                        <InputLabel>{t("TIME")}</InputLabel>
                        <Select
                            // inputProps={{                                
                            //     placeholder: 'Select an Time',
                            // }}
                            id="time"
                            value={form.start_time || 0}
                            onChange={(event) => {
                                const placeTime = document.getElementById('time') as HTMLDivElement;
                                placeTime.style.color = "Black";
                                setSelectedTime(true);
                                setForm({
                                    ...form,
                                    start_time: event.target.value as number,
                                    table_category: null,
                                    courses: [{ id: "", count: 0, authorizationHoldAmount: null }],
                                });
                            }}
                            label={t("TIME")}
                        >
                            
                            <MenuItem value={0}>{t("RESERVE_INFO.SELECT_TIME")}</MenuItem>
                            {seatInfos.slots
                                ?.find((a) => a.date === form.target_date)
                                ?.slots.map((item, index) => {
                                    return (
                                        <MenuItem
                                            key={index}
                                            value={item.start_time}
                                        >
                                            {toretaApi.transformStattTime(
                                                item.start_time
                                            )}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {showTable ? (
                <>
                    <Box mt={2}>
                        <Typography variant="body1" component="h2" gutterBottom>
                            <Box fontWeight="fontWeightBold" m={0}>
                                {t("TABLE_CAETGORY")}
                                <span className={classes.required}>
                                    {" "}
                                    {t("REQUIRED")}
                                </span>
                            </Box>
                        </Typography>
                    </Box>
                    <Grid container className={props.className} spacing={2}>
                        {shop.tableInfo && (
                            <Grid item xs={12}>
                                {shop.tableInfo.split("\n").map((line) => <div><Linkify>{line}</Linkify></div>)}
                            </Grid>
                        )}
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel>
                                    {t("SELECT_TABLE_CAETGORY")}
                                </InputLabel>
                                <Select
                                    value={form.table_category || ""}
                                    onChange={handleSelectTextChange(
                                        "table_category"
                                    )}
                                    label={
                                        t("SELECT_TABLE_CAETGORY") +
                                        (shop.needTable ? t("REQUIRED") : "")
                                    }
                                >
                                    {
                                    canSelectTableCategories.map((item, index) => {
                                        return (
                                            <MenuItem
                                                key={index}
                                                value={item.key_name}
                                                disabled={item.disabled}
                                            >
                                                {getDisplaySeats(item.key_name,item.display_name)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <></>
            )}
            

            {showMenu ? (
                <>
                    <Box mt={2}>
                        <Typography variant="body1" component="h2" gutterBottom>
                            <Box fontWeight="fontWeightBold" m={0}>
                                {t("RESERVE_INFO.SELECT_COURSE")}
                                {shop.needCourse ? <span className={classes.required}>
                                    {" "}
                                    {t("REQUIRED")}
                                </span> : shop.needCourse === null ? <span>
                                    {" "}
                                    {t("OPTION")}
                                </span> : null }
                            </Box>
                        </Typography>
                    </Box>
                    <Grid container className={props.className} spacing={2}>
                        {shop.courseInfo && (
                            <Grid item xs={12}>
                                {shop.courseInfo.split("\n").map((line) => <div><Linkify>{line}</Linkify></div>)}
                            </Grid>
                        )}
                        <Grid item xs={12} spacing={2}>
                            { (courses && courses.length > 0) && <hr style={{borderStyle: 'dashed',  borderWidth: 1, borderColor: '#AEAEAE' }} />}
                        </Grid>

                        {!isMobile && courseDisplay}
                        {isMobile && courseDisplayMobile }
                    </Grid>
                </>
            ) : (
                <></>
            )}

            <Box mt={4}>
                <Typography variant="body1" component="h2" gutterBottom>
                    <Box fontWeight="fontWeightBold" m={0}>
                        {t("REQUEST")}:
                    </Box>
                </Typography>
                {shop.note_message &&
                    shop.note_message.split("\n").map(function (item, key) {
                        return (
                            <span key={key}>
                                {item}
                                <br />
                            </span>
                        );
                    })}
            </Box>
            <Box mt={2}>
                <TextField
                    fullWidth
                    label=""
                    multiline
                    rows={4}
                    defaultValue={form.note}
                    variant="outlined"
                    onChange={handleTextChange("note")}
                />
            </Box>
            <Box mt={5} textAlign="center">
                <GreenButton
                    variant="contained"
                    color="primary"
                    disabled={!goNext}
                    onClick={finishWork}
                >
                    {goNext
                        ? t("HOME.BUTTON_NEXT_TEXT")
                        : t("RESERVE_INFO.BUTTON_DISABLED_TEXT")}
                </GreenButton>
            </Box>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{timeout: 500, style: { background: 'rgba(0, 0, 0, 0.7)' }}}
            >
            {/* <Fade in={open} timeout={500}></Fade> */}
                <div style={{ 
                    height: "50vh", 
                    width: "50vh", 
                    alignItems:'center',
                    justifyContent:'center', 
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <img
                        src={image}
                        style={{ maxHeight: "90%", maxWidth: "90%", borderRadius: "4px"}}
                    />
                    <span style={{color: "white", fontWeight: "bolder", paddingTop: "8px" }}>{imageNm}</span>
                </div>
            </Modal>
            <Modal
                className={classes.modal}
                open={(errMessage ? true : false)}
                onClose={() => {setErrMessage("")}}
                BackdropComponent={Backdrop}
                BackdropProps={{timeout: 500, style: { background: 'rgba(0, 0, 0, 0.7)' }}}
            >
                <div style={{ 
                    width: "314px",
                    height: "218px", 
                    alignItems:'center',
                    justifyContent:'center', 
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    borderRadius: '4px',
                }}>
                    <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '1rem', maxWidth: '267px'}}>
                        {t("RESERVE_INFO.MSG_CHECK_MULTI_TITLE")}
                    </div>
                    <div style={{textAlign: 'center', fontSize: '0.875rem', paddingTop: '25px', paddingBottom: '30px', maxWidth: '267px'}}>
                        {errMessage}
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <GreenButton style={{width: "141px",height: "37px", minWidth: "141px", fontSize: '0.875rem', }} onClick={() => {setErrMessage("")}}>
                            {t("RESERVE_INFO.BUTTON_CHECK_MULTI")}
                        </GreenButton>
                    </div>
                </div>
            </Modal>
            {/* <pre>form: {JSON.stringify(form, null, 1)}</pre>
            <pre>courses: {JSON.stringify(shopinfo.courses, null, 1)}</pre>
            <pre>Holiday: {JSON.stringify(holidaySetting, null, 1)}</pre> */}
        </>
    );
};
export default Step1;
