import React, { useEffect,useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { makeStyles, Theme, createStyles, withStyles } from "@material-ui/core/styles";
import {
    Box,
    Paper,
    FormControlLabel,
    Table,TableBody,TableCell,TableContainer,TableRow,
    Typography,
    Checkbox,CheckboxProps,
} from "@material-ui/core";
import { GreenButton } from "../../Components";
import { green } from "@material-ui/core/colors";

import ToretaApi from "../../ToretaApi";
import { ShopDetail, YoyakuBody, ShopInfo } from "../../common/interface";
import Depositinfo from "./DepositInfo";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link:{
            color: "#000",
            cursor: "pointer",
        },
        checkbox:{
            marginRight:"0px",
        },
        changeLink: {
            color: "#689f38",
            cursor: "pointer",
            float: "right",
        },
        tableTitle: {
            fontWeight: "bolder",
        },
        policyBox: {
            overflowY: "scroll",
            border: "1px rgba(0,0,0,0.12) solid",
            borderRadius: 4,
            padding: theme.spacing(2),
            "&:hover": {
                borderColor: "rgba(0,0,0,0.87)",
            },
        },
    })
);
const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        "&$checked": {
            color: green[600],
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

type Props = {
    initValue: YoyakuBody;
    payable: boolean;
    className: any;
    onNext: (data: YoyakuBody) => void;
    backStep1: () => void;
    backStep2: () => void;
};

const Step3: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    
    const shop: ShopDetail = useSelector((state: any) => state.shop);
    const shopinfo: ShopInfo = useSelector((state: any) => state.shopinfo);

    const { transformStattTime, DisplayCustomername, transformDateByStattTime } = ToretaApi();
    
    const { t } = useTranslation();

    const [checked1, setChecked1] = React.useState(props.initValue.optin_dm ? props.initValue.optin_dm : false);
    const [checked2, setChecked2] = React.useState(false);
    const [checked3, setChecked3] = React.useState(false);
    const toretaApi = ToretaApi();
    const [payable, setPayable] = React.useState(props.payable ? props.payable : false);

    const handleChecked1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked1(event.target.checked);
    };
    const handleChecked2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked2(event.target.checked);
    };
    const handleChecked3Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked3(event.target.checked);
    };
    const currentLanguage: string = useSelector((state: any) => state.lang);
    const footer = {
        jp_ja: {
            privacy: "https://toreta.in/terms/pp_web_reservation/#lang-japanese",
            terms: "https://toreta.in/terms/web_reservation/",
            poweredBy: "https://toreta.in/",
        },
        jp_all: {        
            privacy: "https://toreta.in/terms/pp_web_reservation/#lang-english",
            terms: "https://toreta.in/terms/web_reservation_en/",
            poweredBy: "https://toreta.in/",
        },
        default: {
            privacy: "http://toretaasia.com/privacy-policy/",
            terms: "http://toretaasia.com/terms-of-service-2/",
            poweredBy: "http://toretaasia.com/",
        },
    };
    const [getFooter,setFooter]=React.useState(footer.default);
    const [getDM_OPTIN,setDM_OPTIN]=useState("RESERVE_CONFIRMATION.DM_OPTIN")
    const setFooterMenu=()=>{
        setDM_OPTIN("RESERVE_CONFIRMATION.DM_OPTIN");
        if(shop.company_country=="jp"){
            setDM_OPTIN("RESERVE_CONFIRMATION.DM_OPTIN_JP");
            if(currentLanguage=="ja"){
                setFooter(footer.jp_ja);
            }
            else{
                setFooter(footer.jp_all);
            }
        }else{
            setFooter(footer.default);
        }
    }
    const [checkAddress, setAddress] = useState(false);
    var seatTypeLanguage: { [id: string] : string; } = {};
    const setDisplaySeats=()=>{
        seatTypeLanguage["none"]="RESERVE_INFO.ITEM_NO_PREFERENCE";
        seatTypeLanguage["counter"]="RESERVE_INFO.ITEM_COUNTER_SEAT";
        seatTypeLanguage["private"]="RESERVE_INFO.ITEM_PRIVATE_ROOM";
        seatTypeLanguage["table"]="RESERVE_INFO.ITEM_TABLE_SEAT";
        seatTypeLanguage["outdoor_seat"]="RESERVE_INFO.ITEM_OUTDOOR_SEAT";
        seatTypeLanguage["tatami_seat"]="RESERVE_INFO.ITEM_TATAMI_SEAT"; 
        seatTypeLanguage["bar_counter"]="RESERVE_INFO.ITEM_BAR_COUNTER"; 
        seatTypeLanguage["takeaway"]="RESERVE_INFO.ITEM_TAKEAWAY"; 
        seatTypeLanguage["semi_private"]="RESERVE_INFO.ITEM_SEMI_PRIVATE"; 
        seatTypeLanguage["sofa_seat"]="RESERVE_INFO.ITEM_SOFA_SEAT"; 
        seatTypeLanguage["horigotatsu"]="RESERVE_INFO.ITEM_HORIGOTATSU";     
    }
    React.useEffect(() => {
        setFooterMenu();
    }, [currentLanguage]);
    useEffect(() => {
        setFooterMenu();
        if(shop.address!==null&&shop.address!==""){
            setAddress(true);
        }else{
            setAddress(false);
        }
        setPayable(props.payable);
        const country = shop.country ? shop.country : "en";
        axios.get(`/${country}.txt`).then((response) => {
            // console.log(response.data);
            const target = document.getElementById("policy");
            if (target) {
                target.innerHTML = response.data;
            }
            return "";
        });
        toretaApi.ScrollTop();
        // eslint-disable-next-line
    }, [shop,props.payable]);
    
    const finishWork = () => {
        const newdata = {
            ...props.initValue,
            optin_dm: checked1,
        };
        props.onNext(newdata);//pass YoyakuBody to Next FunctionComponent (Step4)
    };
    interface Map {
        [key: string]: any;
        [index: number]: any;
    }
    const dicCurrency:Map={
        "SGD":"S$",
        "TWD":"NT$",
        "MYR":"RM",
        "THB":"฿",
        "JPY":"¥",
    }
    const getCurrency = (key: string) =>{
        var c= Object.keys(dicCurrency).filter((val,ind) => {return val==key;});
        if(c.length>0){
            return dicCurrency[key];
        }
        return key;
    }
    const formatter = new Intl.NumberFormat('ja-JP', {
        //style: 'currency',
        currency: 'JPY',
      });
    const formatterEn = new Intl.NumberFormat('en-US', {
        currency: 'USD',
    });
    const getCurrencyAmt = (amt: number) =>{
        
        if(shop.currency=="JPY"){
            return formatter.format(Math.trunc(amt));
        }
        return formatterEn.format(amt);
    }
    
    const getDisplaySeats=(key:any)=>{
        setDisplaySeats();
        const defV = shopinfo.tables?.find((a) => a.key_name === key)?.display_name;
        try{ 
        var lang = t(seatTypeLanguage[key]);
        if(lang!=undefined && lang!=null && lang.length>0) 
        {
            return lang;
        }
        return defV;
        }catch{
            return defV;
        }
    }
    return (
        <>
            <Box my={2}>
                <Typography variant="body1" component="h2" gutterBottom>
                    <Box fontWeight="fontWeightBold" m={0}>
                        <span>{t("RESERVE_CONFIRM")}</span>
                    </Box>
                </Typography>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("RESTAURANT_NAME")}</TableCell>
                            <TableCell align="left">{shop.name}</TableCell>
                        </TableRow>
                        { checkAddress ?(
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("ADDRESS")}</TableCell>
                            <TableCell align="left">{shop.address}</TableCell>
                        </TableRow>
                        ):(
                        ""
                        )}
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("PARTY_SIZE")}</TableCell>
                            <TableCell align="left">
                                {props.initValue.seats}
                                {t("PEOPLE")}
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("TIME")}</TableCell>
                            <TableCell align="left">
                                {transformDateByStattTime(props.initValue.target_date as string, props.initValue.start_time as number)}{" "}
                                {transformStattTime(props.initValue.start_time as number)} {t("IN")}
                                {props.initValue.end_time ? " " + transformStattTime(props.initValue.end_time as number) + " " + t("OUT") : ""}
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("TABLE_CAETGORY")}</TableCell>
                            <TableCell align="left">{getDisplaySeats(props.initValue.table_category)}</TableCell>
                        </TableRow>
                        {props.initValue.courses && props.initValue.courses.length > 0 ? (
                            <>
                                <TableRow hover>
                                    <TableCell className={classes.tableTitle}>{t("RESERVE_INFO.SELECT_COURSE")}</TableCell>
                                    <TableCell align="left">
                                        {" "}
                                        {props.initValue.courses?.map((course) => {
                                            const menu = shopinfo.courses?.find((a) => a.id === course.id);
                                            if (!menu) {
                                                return <></>;
                                            } else {
                                                return (
                                                    <>
                                                        {menu?.name}
                                                        {"  "}
                                                        {t("RESERVE_INFO.IN_TAX", {
                                                            
                                                            //price: menu?.price,
                                                            price: (menu.price ? (" " +(shop.company_country!="jp"?(shop.currency + menu.price):getCurrency(shop.currency)+" "+ getCurrencyAmt(parseFloat(menu.price)))) : null),
                                                            seats: course.count,
                                                        })}
                                                        <br />
                                                    </>
                                                );
                                            }
                                        })}
                                    </TableCell>
                                </TableRow>
                            </>
                        ) : (
                            <></>
                        )}
                        <TableRow>
                            <TableCell className={classes.tableTitle}>{t("REQUEST")}</TableCell>
                            <TableCell align="left">{props.initValue.note}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box my={2}>
                <Typography variant="body1" component="h2" gutterBottom>
                    <Box fontWeight="fontWeightBold" m={0}>
                        <span>{t("CONTACT_INFO")}</span>
                    </Box>
                </Typography>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("NAME")}</TableCell>
                            <TableCell align="left">{DisplayCustomername(props.initValue)}</TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("MOBILE")}</TableCell>
                            <TableCell align="left">{props.initValue.phoneview ? props.initValue.phoneview : props.initValue.phone}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box>
                <Depositinfo initValue={props.initValue} />
            </Box>
            {payable ? (<>

            {shop.company_country=="jp"?(
            <Box textAlign="center" my={2}>
                <Box className={classes.policyBox} maxHeight={150} textAlign="left">
                {t("RESERVE_CONFIRMATION.DEPOSIT_EXPLANATION_JP")
                    .split("\n")
                    .map(function (item, key) {
                        return (
                            <span key={key}>
                                {item}
                                <br />
                            </span>
                        );
                    })}
                </Box>
            </Box>):("")}
            
            {shop.company_country=="jp"?(
            <Box textAlign="left" my={2}>
                <Box className={classes.tableTitle} fontWeight="fontWeightBold" m={0}>
                        <span>{t("CANCELLATION_POLICY")}</span>
                </Box>
                <br/>
                
                <Box className={classes.policyBox} maxHeight={150} minHeight={100} textAlign="left">
                {
                shop.cancellationPolicy==null?"":(shop.cancellationPolicy
                .split("\n")
                    .map(function (item, key) {
                        return (
                            <span key={key}>
                                {item}
                                <br />
                            </span>
                        );
                    }))
                }

                </Box>
            </Box>
            ):("")
            }

            <Box textAlign="center" my={2}> 
            {shop.company_country=="jp"?(
                <FormControlLabel className={classes.checkbox} 
                control={<GreenCheckbox checked={checked3} onChange={handleChecked3Change} name="checked" />}
                label=""
                />
                ):("")}
                {shop.company_country=="jp"?(
                    <span>{t("CANCELLATION_POLICY_CHECK")}</span>):("")
                }
                {shop.company_country=="jp"?(<br/>):("")}

                <FormControlLabel className={classes.checkbox} 
                    control={<GreenCheckbox checked={checked2} onChange={handleChecked2Change} name="checked" />}
                    label=""
                />
                {t("RESERVE_CONFIRMATION.CHECKBOX_LABEL").split("|")
                    .map(function (item, key) {
                        if(item.startsWith("TERMS_OF_SERVICE") || item.startsWith("PRIVACY_POLICY")){
                            var links=item.split('$');
                            var link=links[0];
                            var text=links[1];
                            if(link=="TERMS_OF_SERVICE") link=getFooter.terms;
                            else if(link=="PRIVACY_POLICY") link=getFooter.privacy;
                            return (
                                <a target={'_blank'} className={classes.link} href={link}>
                                    {text}
                                </a>
                            );
                        }
                        return (
                            <span key={key}>
                                {item}
                            </span>
                        );
                    })}
                <br/>
                <FormControlLabel className={classes.checkbox} 
                    control={<GreenCheckbox checked={checked1} onChange={handleChecked1Change} name="checked" />}
                    label=""/>
                    {t(getDM_OPTIN)}  
            </Box> 
            <Box textAlign="center" my={2}>
                <GreenButton variant="contained" color="primary" onClick={finishWork} disabled={!checked2 || (shop.company_country=="jp" &&!checked3)} >
                    {t("HOME.BUTTON_PAY_NEXT")}
                </GreenButton>
            </Box>
            </>) : null}
        </>
    );
};
export default Step3;
